import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "gatsby";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomButton from "components/CustomButtons/CustomButton.jsx";
import ButtonESG from "components/CustomButtons/ButtonESG.jsx";
import ButtonWWD from "components/CustomButtons/ButtonWWD.jsx";
import ButtonColliery from "components/CustomButtons/ButtonColliery.jsx";
import DownloadButton from "components/CustomButtons/DownloadButton.jsx";
import SpecButton from "components/CustomButtons/SpecButton.jsx";

import Parallax from "components/Parallax/Parallax.jsx";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import SectionBasics from "./Sections/SectionBasics.jsx";
import SectionNavbars from "./Sections/SectionNavbars.jsx";
import SectionTabs from "./Sections/SectionTabs.jsx";
import SectionPills from "./Sections/SectionPills.jsx";
import SectionNotifications from "./Sections/SectionNotifications.jsx";
import SectionTypography from "./Sections/SectionTypography.jsx";
import SectionJavascript from "./Sections/SectionJavascript.jsx";
import SectionCarousel from "./Sections/SectionCarousel.jsx";
import SectionCompletedExamples from "./Sections/SectionCompletedExamples.jsx";
import SectionLogin from "./Sections/SectionLogin.jsx";
import SectionExamples from "./Sections/SectionExamples.jsx";
import SectionDownload from "./Sections/SectionDownload.jsx";
import ProductSection from "./Sections/ProductSection.jsx";

import portrait from "../../assets/img/portrait2.png";
import specshome from "../../assets/img/specshome.png";
import esgimg from "../../assets/img/ESGHeader.jpg";
import wwd from "../../assets/img/wwd.jpg";
import innerrender from "../../assets/img/InnerRender.jpg";
import Briscombe from "../../assets/img/GreggsBuilding.jpg";
import andrewimage from "../../assets/img/AThomson.jpg";
import image from "../../assets/img/footimg.png";
import colliery from "../../assets/img/colliery.png";

import csStyle from "assets/jss/material-kit-react/views/csPage.jsx";

class CsPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          brand="Material Kit React"
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        <Parallax image={require("assets/img/CaseStudyHeader.jpg")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem>
                <div className={classes.brand}>
                <div className={classes.intro}>
                  Case studies
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.mainwrap}>
          {/* <ProductSection /> */}
          <GridContainer>
          <GridItem xs={12} sm={12} md={6}>    
          <div className={classes.imagewrapteam}>
          <img alt="..." src={Briscombe} className={classes.homeimage} />
          </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}> 
          <div className={classes.preheadingteam}>
          Briscombe <br/><br/>Retail Park
                  </div> 
          <div className={classes.listwrap}>
                <ul className={classes.ulist}>
                  <li>Status – completed </li>
                  <li>Location – Barry South Wales</li>
                  <li>Value - £1.35m</li>
                  <li>Role – Investor developer</li>
                  <li>ROE 30%</li>
                </ul>
                </div>
                </GridItem>
          <GridItem xs={12} sm={12} md={12}>
                 
                <div className={classes.parawrap}>
                <p className={classes.paratextteam}>
                Three previous attempts to achieve a change of use and development for this site had failed. Following our acquisition of the half acre industrial site, our planning application for change of use was successful and we pre-let the planned scheme shortly thereafter. Following pre-let on 10 and 15 year leases to PLC occupiers we started construction, completing in July 2020.  In July 2021 we exited the investment via sale at a NIY of 5.6% creating a 30% ROE for our shareholders.
                <br/>
                
                </p>
              </div>
                
            </GridItem>
            </GridContainer>

            </div>  
          </div>

          <div className={classNames(classes.mainalt, classes.mainRaised)}>
          <div className={classes.mainwrap}>
          {/* <ProductSection /> */}
          <GridContainer>
          <GridItem xs={12} sm={12} md={6}>    
          <div className={classes.imagewrapteam}>
          <img alt="..." src={innerrender} className={classes.homeimage} />
          </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}> 
          <div className={classes.preheadingteam}>
          Mixed Use<br/><br/>Development
                  </div> 
          <div className={classes.listwrap}>
                <ul className={classes.ulist}>
                  <li>Status – Under development </li>
                  <li>Location – Bridgend, South Wales</li>
                  <li>Value - £15m</li>
                  <li>Size- 20 acres</li>
                  <li>Role – Development Manager</li>
                </ul>
                </div>
                </GridItem>
          <GridItem xs={12} sm={12} md={12}>
                 
                <div className={classes.parawrap}>
                <p className={classes.paratextteam}>
                After progress on the project stalled and negotiations with local government stakeholders became strained, the feasibility study started to produce negative values. Virtuous Circle Developments were appointed in Q1 2019 and successfully re-planned the development without incurring additional costs. We restructured the site assembly plan with local government, renegotiated the sites Section 106 agreement and secured the previously stalled development funding from the Welsh Government.
                <br/>
                
                </p>
              </div>
                
            </GridItem>
            </GridContainer>

            </div>  
          </div>

          <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.mainwrap}>
          {/* <ProductSection /> */}

            </div>  
          </div>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.mainwrapfoot}>
          {/* <ProductSection /> */}

          <div className={classes.bgimg}       style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "center center"
          }}>
                    <div className={classes.container}>
            <GridContainer>
              <GridItem>
                <div className={classes.brandfooter}>
                  <div className={classes.footerimagewrap}>
          <img alt="..." src={colliery} className={classes.colliery} />
          </div>
                <ButtonColliery/>
                </div>
              </GridItem>
            </GridContainer>
          </div>
          </div>

            </div>  
          </div>

        <Footer />
      </div>
    );
  }
}

export default withStyles(csStyle)(CsPage);